.icon {
    border: 3px solid transparent;
    border-radius: 100%;
}

.icon-and-title:hover {
    color: #EE5A22;

    .icon {
        border: 3px solid #EE5A22;
        border-radius: 100%;
        transition: all 1s;
    }
}