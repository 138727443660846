/* Importing fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');

/* Creating classes for different fonts */
.lato-regular {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
}
  
.lato-bold {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
}
  
.lato-black {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.montserrat-regular {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.montserrat-bold {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}

/* Creating classes for different colors */
.white {
    color: #ffffff;
}

.black {
    color: #000000;
}

.dark-gray {
    color: #4D4D4D;
}

.light-gray {
    color: #6D6F72;
}

.light-blue {
    color: #009DDF;
}

/* Metallic effect */
.metal {
    position: relative;
    outline: none;
    text-align: center;
    color: hsla(0,0%,20%,1);
    text-shadow: hsla(0,0%,40%,.5) 0 -1px 0, hsla(0,0%,100%,.6) 0 2px 1px; 
    background-color: hsl(0,0%,90%);
    transition: color .2s;
}

.metal.linear {
    border: 2px solid #9E9E9E;
    border-radius: 10px;
    background-image: -webkit-repeating-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0)   6%, hsla(0,0%,100%, .1) 7.5%),
      -webkit-repeating-linear-gradient(left, hsla(0,0%,  0%,0) 0%, hsla(0,0%,  0%,0)   4%, hsla(0,0%,  0%,.03) 4.5%),
      -webkit-repeating-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0) 1.2%, hsla(0,0%,100%,.15) 2.2%),
      
      linear-gradient(180deg, hsl(0,0%,78%)  0%, 
      hsl(0,0%,90%) 47%, 
      hsl(0,0%,78%) 53%,
      hsl(0,0%,70%)100%);
}

/* Custom button */
.custom-btn {
    background-color: #ffffff;
    color: #4A4A4A;
    border: 2px solid #D4D4D4;
    margin-top: 5%;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    padding: 1rem 2rem;
    font-size: 0.9rem;
}

.custom-btn:hover {
    background-color: #E1E1E1;
    color: #4A4A4A;
    border: 2px solid #D4D4D4;
}

/* Custom Carousel */
.gallery .carousel-inner {
    padding-bottom: 2rem;
    margin: auto;
}
  
.gallery .carousel-indicators {
    margin-bottom: 0;
}

/* Custom form */
.form-control {
    border-radius: 0;
    border: 2px solid #C8C7C7;
}

.submit-btn {
    background-color: #ffffff;
    color: #ED4C14;
    border: 2px solid #ED4C14;
    margin-top: 5%;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    padding: 1rem 2rem;
    font-size: 0.9rem;
}

.submit-btn:hover {
    background-color: #ED4C14;
    color: #ffffff;
    border: 2px solid #ED4C14;
}

.custom-toggle-button:hover {
    color: #ED4C14 !important;
}

/* Resolution specific styles */
@media (max-width: 575.98px) {
    .font-title {
        font-size: 2.4rem;
    }
    
    .font-large {
        font-size: 1.6rem;
    }
    
    .font-medium {
        font-size: 1.2rem;
    }
    
    .font-regular {
        font-size: 1rem;
    }

    .font-small {
        font-size: 0.8rem;
    }

    .gallery .carousel-control-next, .gallery .carousel-control-prev {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 768.98px) {
    .font-title {
        font-size: 2.4rem;
    }
    
    .font-large {
        font-size: 1.6rem;
    }
    
    .font-medium {
        font-size: 1.2rem;
    }
    
    .font-regular {
        font-size: 1rem;
    }

    .font-small {
        font-size: 0.8rem;
    }

    .gallery .carousel-control-next, .gallery .carousel-control-prev {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .font-title {
        font-size: 2.4rem;
    }

    .font-large {
        font-size: 1.6rem;
    }
    
    .font-medium {
        font-size: 1.2rem;
    }
    
    .font-regular {
        font-size: 1rem;
    }

    .font-small {
        font-size: 0.8rem;
    }

    .gallery .carousel-control-next, .gallery .carousel-control-prev {
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .font-title {
        font-size: 2.6rem;
    }
    
    .font-large {
        font-size: 1.8rem;
    }
    
    .font-medium {
        font-size: 1.3rem;
    }
    
    .font-regular {
        font-size: 1rem;
    }

    .font-small {
        font-size: 0.8rem;
    }

    .gallery .carousel-image {
        object-fit: contain;
        height: 60vh;
    }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
    .font-title {
        font-size: 2.6rem;
    }

    .font-large {
        font-size: 1.8rem;
    }
    
    .font-medium {
        font-size: 1.3rem;
    }
    
    .font-regular {
        font-size: 1rem;
    }

    .font-small {
        font-size: 0.8rem;
    }

    .gallery .carousel-image {
        object-fit: contain;
        height: 60vh;
    }
}

@media (min-width: 1400px) {
    .font-title {
        font-size: 2.6rem;
    }
    
    .font-large {
        font-size: 1.8rem;
    }
    
    .font-medium {
        font-size: 1.3rem;
    }
    
    .font-regular {
        font-size: 1rem;
    }

    .font-small {
        font-size: 0.8rem;
    }

    .gallery .carousel-image {
        object-fit: contain;
        height: 60vh;
    }
}